import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Wrapper from './IconBuilder.styles';

const IconBuilder = () => {
    const navigate = useNavigate();
    const { token } = useSelector(store => store.app);

    useEffect(() => {
        if (!token) {
            navigate('/login', { replace: true });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <div className="form">
                <h2 className="title">Icon Builder</h2>
                <div className="title-underline"></div>
            </div>
        </Wrapper>
    );
};
export default IconBuilder;
