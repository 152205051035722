import styled from 'styled-components';

const Wrapper = styled.main`
    min-height: 100vh;
    display: flex;
    align-items: center;

    .btn-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
`;

export default Wrapper;
