import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    getNeuralprints,
    newSnapshot,
    triggerMongoToJSON,
} from '../../services/neuralprints';
import Loading from '../../components/Loading';
import Wrapper from './AddSnapshot.styles';

const initialValues = {
    neuralprintId: '',
    snapshot: 'new',
    json: '',
};

const AddSnapshot = () => {
    const navigate = useNavigate();
    const { token } = useSelector(store => store.app);

    const [isLoading, setIsLoading] = useState(true);
    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [isNewNeuralprint, setIsNewNeuralprint] = useState(false);
    const [neuralprints, setNeuralprints] = useState([]);
    const [values, setValues] = useState(initialValues);

    const newNeuralprintRef = useRef(null);

    const ctaButtonDisabled =
        values.neuralprintId && values.snapshot && values.json ? false : true;

    const handleValuesChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        setValues({ ...values, snapshot: 'new', [name]: value });

        if (name === 'neuralprintId') {
            setIsNewNeuralprint(value === 'new' ? true : false);
        }
    };

    const handleFileChange = e => {
        const file = e.target.files[0];

        if (!file) {
            toast.error('No file uploaded');
            return;
        }
        if (!file.name.endsWith('.json') && !file.name.endsWith('.txt')) {
            toast.error('Invalid file type');
            return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', function (e) {
            try {
                const json = JSON.parse(e.target.result);
                setValues({ ...values, json });
            } catch (error) {
                toast.error('File does not contain valid JSON');
                return;
            }
        });
        reader.readAsText(file, 'UTF-8');
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const payload = { ...values };

        if (payload.neuralprintId === 'new') {
            payload.neuralprintId = newNeuralprintRef.current.value;
        }

        const { message, error } = await newSnapshot(payload);

        if (error) {
            toast.error(error);
        } else {
            toast.success(message);
            setValues({ ...initialValues });
            setIsNewNeuralprint(false);
            fetchNeuralprints();
        }
    };

    const handleMongoToJSONClick = async e => {
        const { message, error } = await triggerMongoToJSON();

        if (error) {
            toast.error(error);
        } else {
            toast.success(message);
        }
    };

    const fetchNeuralprints = async () => {
        const { neuralprints, error } = await getNeuralprints();

        if (error) {
            setIsInvalidToken(true);
        } else {
            setNeuralprints(neuralprints);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!token) {
            navigate('/login', { replace: true });
            return;
        }
        fetchNeuralprints();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading)
        return (
            <Wrapper>
                <div className="form">
                    <Loading center />
                </div>
            </Wrapper>
        );

    if (isInvalidToken) {
        return (
            <Wrapper>
                <div className="form">
                    <h2 className="title">Invalid token</h2>
                    <div className="title-underline"></div>
                    <Link className="go-back-link" to="/login">
                        Go back to login and try again
                    </Link>
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <form className="form" onSubmit={handleSubmit}>
                <h2 className="title">Add Snapshot</h2>
                <div className="title-underline"></div>
                <div className="form-row">
                    <label className="form-label" htmlFor="neuralprintId">
                        Select Neuralprint ID:
                    </label>
                    <select
                        className="form-select"
                        name="neuralprintId"
                        id="neuralprintId"
                        value={values.neuralprintId}
                        onChange={handleValuesChange}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        <option value="new">New</option>
                        {neuralprints.map(bp => (
                            <option key={bp._id} value={bp.neuralprintID}>
                                {bp.neuralprintID}
                            </option>
                        ))}
                    </select>
                </div>
                {values.neuralprintId && (
                    <>
                        {isNewNeuralprint ? (
                            <div className="form-row">
                                <label
                                    className="form-label"
                                    htmlFor="newNeuralprintId"
                                >
                                    Enter New Neuralprint ID:
                                </label>
                                <input
                                    className="form-input"
                                    type="text"
                                    name="newNeuralprintId"
                                    id="newNeuralprintId"
                                    ref={newNeuralprintRef}
                                />
                            </div>
                        ) : (
                            <div className="form-row">
                                <label
                                    className="form-label"
                                    htmlFor="snapshot"
                                >
                                    Select Snapshot:
                                </label>
                                <select
                                    className="form-select"
                                    name="snapshot"
                                    id="snapshot"
                                    value={values.snapshot}
                                    onChange={handleValuesChange}
                                >
                                    <option value="new">New</option>
                                    {!isNewNeuralprint &&
                                        neuralprints[
                                            neuralprints.findIndex(
                                                bp =>
                                                    bp.neuralprintID ===
                                                    values.neuralprintId
                                            )
                                        ].snapshots.map((_, i) => (
                                            <option key={i} value={i}>
                                                {`Snapshot${i + 1}`}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}
                        <div className="form-row">
                            <label htmlFor="file" className="form-label">
                                Upload JSON File:
                            </label>
                            <input type="file" onChange={handleFileChange} />
                        </div>
                        <button
                            type="submit"
                            className="btn"
                            disabled={ctaButtonDisabled}
                        >
                            Submit
                        </button>
                    </>
                )}
                <button
                    type="button"
                    className="btn btn-hipster"
                    onClick={handleMongoToJSONClick}
                >
                    MongoToJSON
                </button>
            </form>
        </Wrapper>
    );
};

export default AddSnapshot;
