import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token'),
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      localStorage.setItem('token', payload);
      state.token = payload;
    },
  },
});

export const { setToken } = appSlice.actions;
export default appSlice.reducer;
