import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Home, Login, AddSnapshot, IconBuilder } from './pages';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="add-snapshot" element={<AddSnapshot />} />
                <Route path="icon-builder" element={<IconBuilder />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <ToastContainer position="top-center" />
        </BrowserRouter>
    );
}

export default App;
