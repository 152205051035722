import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Wrapper from './Home.styles';

const Home = () => {
    const navigate = useNavigate();
    const { token } = useSelector(store => store.app);

    useEffect(() => {
        if (!token) {
            navigate('/login', { replace: true });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <div className="form">
                <h2 className="title">Home</h2>
                <div className="title-underline"></div>
                <div className="btn-container">
                    <Link className="btn" to="/add-snapshot">
                        Add Snapshot
                    </Link>
                    <Link className="btn" to="/icon-builder">
                        Icon Builder
                    </Link>
                </div>
            </div>
        </Wrapper>
    );
};
export default Home;
