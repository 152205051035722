import styled from 'styled-components';

const Wrapper = styled.main`
    min-height: 100vh;
    display: flex;
    align-items: center;

    .go-back-link {
        display: block;
        text-align: center;
    }

    .btn-hipster {
        display: block;
        margin-top: 1rem;
    }
`;

export default Wrapper;
