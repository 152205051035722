import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../../features/app/appSlice';
import Wrapper from './Login.styles';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenInputRef = useRef(null);

    const handleSubmit = e => {
        e.preventDefault();

        const token = tokenInputRef.current.value;
        dispatch(setToken(token));

        navigate('/');
    };

    return (
        <Wrapper>
            <form className="form" onSubmit={handleSubmit}>
                <h2 className="title">Login</h2>
                <div className="title-underline"></div>
                <div className="form-row">
                    <label className="form-label" htmlFor="token">
                        Enter token:
                    </label>
                    <input
                        className="form-input"
                        type="text"
                        ref={tokenInputRef}
                    />
                </div>
                <button type="submit" className="btn">
                    Submit
                </button>
            </form>
        </Wrapper>
    );
};

export default Login;
