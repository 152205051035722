import axios from 'axios';

export const getNeuralprints = async () => {
    const token = localStorage.getItem('token');
    try {
        const { data } = await axios.get('/client-api/neuralprints', {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { neuralprints: data };
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const newSnapshot = async payload => {
    const token = localStorage.getItem('token');
    try {
        const { data } = await axios.post('/client-api/neuralprints', payload, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { message: data.message };
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const triggerMongoToJSON = async () => {
    const token = localStorage.getItem('token');
    try {
        const { data } = await axios.get('/mongoToJSON', {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { message: data.message };
    } catch (error) {
        return { error: error.response.data.message };
    }
};
